import React from 'react';
import {
  Container,
  Hidden,
  Grid,
  Typography,
  Theme,
  Box,
  Button,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { PaymentTime } from '../../../modules/openpay/openpay-constants';
import { Slider } from '../slider/Slider';
import { useAuth } from '../../../modules/auth/auth-hook';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    lineThrough: {
      textDecoration: 'line-through',
    },
    lineStrike: {
      textDecoration: 'line-through',
      textDecorationColor: 'red',
    },
    opacity: {
      opacity: 0.75,
    },
    buttonConnect: {
      background: '#FA0000!important',
      color: 'white!important',
      width: '180px',
      height: 45,
    },
    buttonConnectBlue: {
      background: '#0617B4!important',
      color: 'white!important',
      width: '180px',
      height: 45,
    },
    buttonConnectGreen: {
      background: '#00B536!important',
      color: 'white!important',
      width: '180px',
      height: 45,
    },
  }),
);

export const SubTypes: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth();

  const items = [
    <Grid item xs={12} sm={6} lg={4} my={'20px'}>
      <Box
        position={'relative'}
        border={'1px solid #FA0000'}
        borderRadius={'5px'}
      >
        {/**
         * <Box
          position={'absolute'}
          zIndex={-1}
          top={0}
          right={0}
          height={'100%'}
        >
          <img
            src="/Fish.png"
            alt="logo"
            width={120}
            height={120}
            style={{ flexGrow: 1 }}
          />
        </Box>
        <Box position={'absolute'} zIndex={-1} top={0} left={0} height={'100%'}>
          <img
            src="/Shells.png"
            alt="logo"
            width={120}
            height={120}
            style={{ flexGrow: 1 }}
          />
        </Box>
         */}
        {/**
         * <Box position={'absolute'} top={'-16px'} left={0} width={'100%'}>
          <Typography
            color={'white'}
            borderRadius={'15px'}
            px={'10px'}
            bgcolor={'white'}
            border={'1px solid #FA0000'}
            width={200}
            mx={'auto'}
            textAlign={'center'}
            fontSize={20}
          >
            <img
              src="/esta-primaver.png"
              alt="texto"
              height={30}
              width={200}
              style={{ objectFit: 'contain' }}
            />
          </Typography>
        </Box>
         */}
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={'25px'}
          mb={'10px'}
        >
          <Typography fontSize={'22px'} color={'#FA0000'}>
            Plan Mensual
          </Typography>
        </Box>
        <Box
          mb={'20px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          {/**
             * <Typography
            className={classes.lineThrough}
            display={'flex'}
            alignSelf={'center'}
            mr={'5px'}
          >
            antes $99
          </Typography>
             */}
          <Typography
            color={'white'} /** #FA0000*/
            borderRadius={'15px'}
            px={'10px'}
            fontWeight={700}
            bgcolor={'#FA0000'} /**#FEDBDB */
          >
            Ahorra 90%{/**+200% */}
          </Typography>
        </Box>
        <Box
          mb={'10px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontSize={'44px'} lineHeight={'44px'} fontWeight={'700'}>
            $10*
          </Typography>
          {/**
             * <Typography width={'55px'} paddingLeft={1} fontSize={'12px'}>
            pesos al mes.
          </Typography>
             */}
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <img
            src="/logo_month.png"
            alt="texto"
            height={122}
            width={150}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          {/**
             * <Typography
            px={'20px'}
            fontSize={'22px'}
            fontWeight={'700'}
            textAlign={'center'}
          >
            Se parte de Noroeste y recibe invitaciones a eventos exclusivos.
          </Typography>
             */}
          <Typography
            px={'20px'}
            fontSize={'22px'}
            fontWeight={'700'}
            textAlign={'center'}
            lineHeight={'26px'}
            mb={1}
          >
            Prueba nuestra suscripción <br />
            digital pagando solo $10 al mes*
          </Typography>
        </Box>
        <Box
          mb={'10px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Button
            className={classes.buttonConnect}
            onClick={() => {
              if (user)
                navigate(`/metodos-de-pago?payment_type=${PaymentTime.Daily}`);
              else
                window.location.href = `${window.location.origin}/autenticacion?redirect_url=${window.location.origin}/metodos-de-pago?payment_type=${PaymentTime.Daily}`;
            }}
          >
            QUIERO PROBAR
          </Button>
        </Box>
        <Box
          mb={'10px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontWeight={'700'} textAlign={'center'}>
            ¡Cancela cuando quieras!
          </Typography>
        </Box>
        <Box
          mb={'20px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            fontSize={'12px'}
            fontWeight={'700'}
            width={250}
            textAlign={'center'}
          >
            *Se cobrarán $30 por los 3 primeros meses, y $99 a partir del 4to
            mes.
          </Typography>
        </Box>
      </Box>
    </Grid>,
    <Grid item xs={12} sm={6} lg={4} my={'20px'}>
      <Box
        position={'relative'}
        border={'1px solid #5795FF'}
        borderRadius={'5px'}
      >
        <Box position={'absolute'} top={'-16px'} left={0} width={'100%'}>
          <Typography
            color={'black'}
            borderRadius={'15px'}
            px={'10px'}
            bgcolor={'white'}
            border={'1px solid #5795FF'}
            width={200}
            mx={'auto'}
            textAlign={'center'}
            fontSize={20}
          >
            Opcion frecuente ★
          </Typography>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={'25px'}
          mb={'10px'}
        >
          <Typography fontSize={'22px'} color={'#5795FF'}>
            Plan Semestral
          </Typography>
        </Box>
        <Box
          mb={'30px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            className={classes.lineThrough}
            display={'flex'}
            alignSelf={'center'}
            mr={'5px'}
          ></Typography>
          <Typography
            color={'#5795FF'}
            borderRadius={'15px'}
            px={'10px'}
            bgcolor={'#DCDEF4'}
          >
            Ahorra 15%
          </Typography>
        </Box>
        <Box
          mb={'5px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            fontSize={'44px'}
            position={'relative'}
            fontWeight={'700'}
          >
            <span> $499*</span>
            {/**
               * <Box
              position={'absolute'}
              top={'0'}
              left={'0'}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
              width={'100%'}
              className={classes.opacity}
            >
              <img
                src="/equis1.svg"
                alt="logo"
                width={70}
                height={70}
                style={{ flexGrow: 1 }}
              />
            </Box>
               */}
          </Typography>
        </Box>
        {/**
           * <Box
          height={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          width={'100%'}
        >
          <img
            src="/flecha1.svg"
            alt="logo"
            width={90}
            height={90}
            style={{ flexGrow: 1 }}
          />
        </Box>
        <Box
          mb={'10px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontSize={'44px'} fontWeight={'700'}>
            $375
          </Typography>
        </Box>
           */}

        <Box
          my={'77px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            px={'20px'}
            fontSize={'22px'}
            fontWeight={'700'}
            textAlign={'center'}
          >
            ¡Paga 5 meses y recibe 6! <br />
          </Typography>
        </Box>
        <Box
          mb={'15px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Button
            className={classes.buttonConnectBlue}
            onClick={() => {
              if (user)
                navigate(
                  `/metodos-de-pago?payment_type=${PaymentTime.Monthly}`,
                );
              else
                window.location.href = `${window.location.origin}/autenticacion?redirect_url=${window.location.origin}/metodos-de-pago?payment_type=${PaymentTime.Monthly}`;
            }}
          >
            SUSCRIBIRME
          </Button>
        </Box>
        <Box
          mb={'10px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontWeight={'700'} textAlign={'center'}>
            ¡Te ahorras el 15%!
          </Typography>
        </Box>
        <Box
          mb={'35px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontSize={'12px'} fontWeight={'700'} textAlign={'center'}>
            ¡Cancela cuando quieras!
          </Typography>
        </Box>
        {/**
           * <Box
          mb={'20px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontSize={'12px'} fontWeight={'700'} textAlign={'center'}>
            *Promocion vigente hasta el 31 de enero de 2024.
          </Typography>
        </Box>
           */}
      </Box>
    </Grid>,
    <Grid item xs={12} sm={6} lg={4} my={'20px'}>
      <Box
        position={'relative'}
        border={'1px solid #9FE3B3'}
        borderRadius={'5px'}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={'20px'}
          mb={'10px'}
        >
          <Typography fontSize={'22px'} color={'#00B536'}>
            Plan Anual
          </Typography>
        </Box>

        {
          <Box position={'absolute'} top={'-16px'} left={0} width={'100%'}>
            <Typography
              color={'white'}
              borderRadius={'15px'}
              px={'10px'}
              bgcolor={'#00B536'}
              border={'1px solid #9FE3B3'}
              width={250}
              mx={'auto'}
              textAlign={'center'}
              fontSize={20}
            >
              Promocion de aniversario
            </Typography>
          </Box>
        }

        <Box
          mb={'4px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            className={classes.lineThrough}
            display={'flex'}
            alignSelf={'center'}
            mr={'5px'}
          ></Typography>
          <Typography
            color={'white'} /**#00B536 */
            borderRadius={'15px'}
            px={'10px'}
            bgcolor={'#00B536'} /**#D7F1DF */
            fontWeight={'bold'}
            fontSize={18}
          >
            Ahorra 50%
          </Typography>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontSize={'22px'} fontWeight={'700'} textAlign={'center'}>
            ¡Paga 6 y recibe 12 meses*!
          </Typography>
        </Box>
        <Box
          mb={'0px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            fontSize={'44px'}
            position={'relative'}
            fontWeight={'700'}
          >
            <span> $599*</span>
            {/**
               * <Box
              position={'absolute'}
              top={'0'}
              left={'0'}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
              width={'100%'}
              className={classes.opacity}
            >
              <img
                src="/equis1.svg"
                alt="logo"
                width={70}
                height={70}
                style={{ flexGrow: 1 }}
              />
            </Box>
               */}
          </Typography>
        </Box>
        {/**
           * <Box
          height={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          width={'100%'}
        >
          <img
            src="/flecha1.svg"
            alt="logo"
            width={90}
            height={90}
            style={{ flexGrow: 1 }}
          />
        </Box>
           */}
        {/**
           * <Box
          mb={'10px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontSize={'44px'} fontWeight={'700'}>
            $699
          </Typography>
        </Box>
           */}
        <Box
          mb={'38px'}
          mt={'20px'}
          height={'72px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <img src="/hielera.png" alt="logo" width={90} height={120} />
        </Box>
        <Box
          //my={'72px'}
          mb={'13px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            px={'20px'}
            fontSize={'22px'}
            fontWeight={'700'}
            textAlign={'center'}
          >
            ¡Con hielera de regalo!**
          </Typography>
        </Box>

        <Box
          mb={'20px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Button
            className={classes.buttonConnectGreen}
            onClick={() => {
              if (user)
                navigate(
                  `/metodos-de-pago?payment_type=${PaymentTime.Annually}`,
                );
              else
                window.location.href = `${window.location.origin}/autenticacion?redirect_url=${window.location.origin}/metodos-de-pago?payment_type=${PaymentTime.Annually}`;
            }}
          >
            SUSCRIBIRME
          </Button>
        </Box>
        <Box
          mb={'10px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontWeight={'700'} textAlign={'center'}>
            * $899 a partir del segundo año
          </Typography>
        </Box>
        <Box
          mb={'35px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography fontSize={'12px'} fontWeight={'700'} textAlign={'center'}>
            ** Solo Culiacán y Mazatlán
          </Typography>
        </Box>
        {/**
           * <Box
          mb={'20px'}
          display={'flex'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Typography
            fontSize={'12px'}
            px={2}
            paddingTop={'10px'}
            fontWeight={'700'}
            textAlign={'center'}
          >
            *Promocion vigente hasta el 31 de enero de 2024.
          </Typography>
        </Box>
           */}
      </Box>
    </Grid>,
  ];
  return (
    <Container style={{ marginBottom: '15px' }}>
      <Hidden smDown={true} smUp={false}>
        <Box>
          <Grid
            container
            spacing={'20px'}
            maxWidth={'1080px'}
            margin={'0 auto'}
          >
            {items.map((item) => (
              <>{item}</>
            ))}
          </Grid>
        </Box>
      </Hidden>
      <Hidden smDown={false} smUp={true}>
        <Box
          display={'relative'}
          margin={'0 auto'}
          justifyContent={'center'}
          maxWidth={'350px'}
        >
          <Slider items={items} controls infinite />
        </Box>
      </Hidden>
    </Container>
  );
};
